@import '../theme.scss';

.container {
  &__modal {
    z-index: $zindex-modal;
    background-color: rgba(73, 62, 84, 0.5);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &--inactive {
      display: none;
    }
  }
}

.modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    opacity: initial !important;
    background: white;
    border-radius: 5px;
    min-width: fit-content;
  }
}
