@import '../theme.scss';

.container {
  &__panel {
    background: #fff;
    border-radius: $borderRadius;
    border: 1px solid $borderDark;
    box-shadow: $dropShadowLight;
    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray3;
      font-size: larger;
      font-weight: bolder;
      text-transform: uppercase;
      border-bottom: 1px solid $borderDark;
      border-radius: $borderRadius $borderRadius 0 0;
      background: $offWhite;
      &--string {
        user-select: none;
      }
    }
    .body {
      flex: 1;
    }
  }
}
