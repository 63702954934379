@import '../../../theme.scss';

.container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50px calc(100vh - 50px);
  grid-template-areas:
    'header'
    'content';
}

.header {
  grid-area: header;
  background: linear-gradient(to right, $gray4, $gray5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  h4 {
    user-select: none;
    margin: 0;
  }
}

.main {
  grid-area: content;
  padding: 5px;
  overflow: hidden;
}

.scroll {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.panel {
    display: flex;
;}
