@import '../theme.scss';

.tabs {
  &__container {
    background-color: $white-darker;
  }
  &__header {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
    justify-content: space-around;
  }
  &__main {
    width: 100%;
    height: 100%;
  }
}

.tab {
  &__panel {
    width: 100%;
    height: 100%;
  }
  &__title {
    width: 100%;
    cursor: pointer;
    user-select: none;
    color: $gray3;
    font-size: larger;
    text-align: center;
    font-weight: bolder;
    text-transform: uppercase;
    background-color: $whiteDark;
    border-bottom: 1px solid $borderDark;
    &--selected {
      background-color: $white-darker;
      border-right: 1px solid $borderDark;
      border-left: 1px solid $borderDark;
      border-bottom: unset;
    }
    &:first-of-type {
      border-left: unset;
    }
  }
}
