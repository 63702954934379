@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik-regular.woff') format('woff2'), url('./fonts/rubik-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('./fonts/rubik-medium.woff') format('woff2'), url('./fonts/rubik-medium.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

// Sentry icon font

@font-face {
  font-family: 'sentry-simple';
  src: url('./fonts/sentry-simple.eot?-iwi56z');
  src: url('./fonts/sentry-simple.eot?#iefix-iwi56z') format('embedded-opentype'),
    url('./fonts/sentry-simple.woff?-iwi56z') format('woff'), url('./fonts/sentry-simple.woff2?-lhf5py') format('woff2'),
    url('./fonts/sentry-simple.ttf?-iwi56z') format('truetype'),
    url('./fonts/sentry-simple.svg?-iwi56z#sentry-simple') format('svg');
  font-weight: normal;
  font-style: normal;
}
