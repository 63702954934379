.container {
  max-width: 410px;
  width: 100%;
  text-align: center;
  .title {
    height: 280px;
    position: relative;
    z-index: -1;
    h1 {
      font-family: 'Montserrat', sans-serif;
      font-size: 230px;
      margin: 0px;
      font-weight: 900;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background: url('./asset/bg.jpg') no-repeat;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: cover;
      background-position: center;
    }
  }
  h2 {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  .link {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: #0046d5;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: #fff;
    font-weight: 700;
    box-shadow: 0px 4px 15px -5px #0046d5;
  }
}

.view {
  position: relative;
  height: 100vh;
  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 767px) {
  .container .title {
    height: 142px;
  }
  .container .title h1 {
    font-size: 112px;
  }
}
