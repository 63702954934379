@import '../../theme.scss';

.sidebar {
  grid-area: sidebar;
  background: linear-gradient($gray4, $gray5);
  color: #9586a5;
  z-index: $zindex-sidebar;
  display: flex;
  flex-direction: column;
  min-width: fit-content;
  padding: 5px;
  &--collapsed {
    max-width: unset;
  }
  &__container {
    grid-template-columns: 50px auto;
  }
  &__section {
    &--actions {
      margin-top: auto;
    }
  }
  &__item {
    padding: 5px 10px;
    transition: 0.15s color linear;
    &:hover,
    &:focus {
      color: $gray1;
    }
    &--clickable {
      cursor: pointer;
    }
    &__icon {
      content: '';
      display: inline-block;
      width: 32px;
      height: 24px;
      font-size: 20px;
      svg {
        display: block;
        margin: 0 auto;
      }
    }
    &__label {
      margin-left: 12px;
      white-space: nowrap;
      opacity: 1;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &--textOverflow {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.container {
  display: grid;
  height: 100vh;
  width: 100vw;
  grid-template-columns: 55px auto;
  grid-template-rows: auto;
  grid-template-areas: 'sidebar main';
}

.main {
  grid-area: main;
  background-color: #fbfbfc;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
