@import '../../theme.scss';

.input {
  color: $gray5;
  display: block;
  width: inherit;
  background: #fff;
  border: 1px solid $borderDark;
  border-radius: $borderRadius;
  box-shadow: inset $dropShadowLight;
  padding: 0.5em;
  transition: border 0.1s linear;
  resize: vertical;
  &:focus {
    outline: none;
  }
  &:hover,
  &:focus,
  &:active {
    border: 1px solid $gray1;
  }
  &::placeholder {
    color: $gray2;
  }
}
