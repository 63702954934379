.dnd {
  &--drag {
    &__container {
      opacity: 1;
      &.isDragging {
        opacity: 0;
      }
    }
    &__handler {
      cursor: move;
      align-items: center;
      display: inline-flex;
      justify-content: center;
    }
  }
  &--drop {
    &__container {
    }
  }
}
