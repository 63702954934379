@import '../../theme.scss';

.view {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  max-width: 600px;
  display: grid;
  grid-template-columns: 1fr 9fr;
  grid-template-rows: auto;
  grid-template-areas: 'sidebar main';
  background: #fff;
  border: 1px solid #e2dee6;
  border-radius: 3px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
}

.sidebar {
  grid-area: sidebar;
  background: #564f64;
  background-image: linear-gradient(-180deg, rgba(52, 44, 62, 0), rgba(52, 44, 62, 0.5));
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px 0 0 4px;
  text-align: center;
}

.main {
  grid-area: main;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 4fr;
  grid-template-areas:
    'header'
    'content';
}

.header {
  grid-area: header;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  border-bottom: 1px solid $gray1;
  h3 {
    margin-bottom: 20px;
  }
}

.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'form logo';
}

.form {
  grid-area: form;
  align-self: center;
  padding: 30px;
}

.logo {
  grid-area: logo;
  margin: 30px 0;
  .container {
    width: 100%;
    height: 100%;
    padding: 30px;
    border-left: 1px solid #bdb4c7;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
