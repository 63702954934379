@import '../../../../theme.scss';

.button {
  display: inline-block;
  line-height: 1;
  border-radius: $button-borderRadius;
  padding: 8px;
  text-transform: none;
  font-size: $fontSizeMedium;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  color: $button-default-color;
  background: $button-default-background;
  border: 1px solid $button-default-border;
  &:focus {
    outline: none;
  }
  &:hover {
    color: $button-default-color;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $button-default-color;
    background: $button-default-backgroundActive;
    border: 1px solid $button-default-borderActive;
  }
  &.busy {
    opacity: 0.65;
  }
  &.disabled {
    opacity: 0.65;
    color: #ced3d6;
    border-color: #e3e5e6;
    box-shadow: none;
    cursor: default;
    pointer-events: none;
  }
  &.primary {
    color: $button-primary-color;
    background: $button-primary-background;
    border: 1px solid $button-primary-border;
    &:hover {
      color: $button-primary-color;
    }
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $button-primary-color;
      background: $button-primary-backgroundActive;
      border: 1px solid $button-primary-borderActive;
    }
  }
}

.inputNumber {
  width: fit-content;
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: min-content min-content;
  grid-template-areas:
    'container'
    'info';
  &__container {
    grid-area: container;
    width: fit-content;
    display: grid;
    grid-template-rows: min-content;
    grid-template-columns: min-content;
    grid-template-areas: 'input';
    &--hasHandlers {
      grid-template-rows: auto;
      grid-template-columns: auto auto;
      grid-template-areas: 'input handlers';
    }
    &--isHovered {
      .inputNumberHandler {
        visibility: visible !important;
      }
    }
    border-bottom: 2px solid $gray-lightest;
    &--isFocused {
      border-bottom: 2px solid $purple3;
    }
  }
  &__input {
    grid-area: input;
    color: $gray5;
    display: block;
    width: inherit;
    background: #fff;
    border: none;
    padding: 0.25em;
    resize: vertical;
    text-align: center;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: $gray2;
    }
    &--readOnly {
      user-select: none;
      cursor: not-allowed;
    }
  }
  &__handlers {
    grid-area: handlers;
    display: grid;
    grid-template-columns: min-content;
    grid-template-rows: min-content min-content;
    grid-template-areas:
      'up'
      'down';
    .inputNumberHandler {
      cursor: pointer;
      user-select: none;
      visibility: hidden;
      &:hover {
        transform: scale(1.5);
      }
      &--up {
        grid-area: up;
      }
      &--down {
        grid-area: down;
      }
    }
  }
  &__info {
    grid-area: info;
    .inputNumberInfo {
      color: $gray1;
      font-size: smaller;
      font-style: italic;
      display: flex;
      justify-content: center;
    }
  }
}

.select-container {
  grid-area: reference;
  width: 100%;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;

  .select {
    width: 100%
  }

  .controls {
    display: flex;
    vertical-align: center;
    align-self: center;
  }
}

.date--today {
  grid-area: date;
  text-align: center;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header,
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__header {
    width: 100%;
    margin-bottom: 10px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 70% 17%;
    grid-template-areas:
      'headerReferenceProduit headerDate'
      'reference date';
    &--reference-produit {
      grid-area: headerReferenceProduit;
      padding: 0 10px;
    }
    &--date {
      grid-area: headerDate;
      text-align: center;
    }
  }
  &__footer {
    width: 100%;
    display: grid;
    padding: 0 25px 25px;
    align-items: center;
    justify-items: center;
    grid-template-rows: auto;
    grid-template-columns: 50% 50%;
    grid-template-areas: 'stocks actions';
    .stocks {
      grid-area: stocks;
      justify-self: self-start;
      .stock {
        display: grid;
        align-items: center;
        justify-items: center;
        grid-template-rows: auto;
        grid-template-columns: 100px auto;
        grid-template-areas: 'label input';
        &__label {
          grid-area: label;
        }
        &__input {
          width: 100%;
          height: 100%;
          grid-area: input;
          padding: 5px 25px;
          .inputNumber__container {
            width: 125px;
          }
        }
      }
    }
    .actions {
      display: flex;
      width: inherit;
      grid-area: actions;
      justify-content: space-evenly;
      padding-top: 30px;
    }
  }
  &__table {
    padding: 0 25px;
    margin-bottom: 10px;
  }
  .table {
    &__row {
      display: grid;
      grid-area: row;
      align-items: center;
      justify-items: center;
      grid-template-rows: auto;
      grid-template-columns: 100px 175px 175px 175px;
      grid-template-areas: 'week meteo retenue production';
      &--header {
        grid-template-columns: 100px 350px 175px;
        grid-template-areas: 'switcher previsionVente prevision';
      }
    }
    &__cell {
      min-height: 30px;
      &--switcher {
        grid-area: switcher;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.first {
          border-top-left-radius: 5px;
        }
        padding-right: 5px;
      }
      &--prevision-vente {
        grid-area: previsionVente;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.first {
          border-top-left-radius: 5px;
        }
      }
      &--prevision {
        grid-area: prevision;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.first {
          border-top-right-radius: 5px;
        }
      }
      &--prevision-vente,
      &--prevision {
        &.first {
          user-select: none;
          text-align: center;
          border-top: 1px solid $gray1;
        }
      }
      &--week {
        width: 100px;
        grid-area: week;
        text-align: center;
        padding-right: 15px;
        &.first {
          user-select: none;
        }
      }
      &--meteo,
      &--retenue,
      &--production {
        width: 100%;
        height: 100%;
        padding: 5px 25px;
        .inputNumber__container {
          width: 125px;
        }
        &.first {
          user-select: none;
          text-align: center;
        }
        &.last {
          border-bottom: 1px solid $gray1;
        }
      }
      &--meteo,
      &--prevision-vente {
        border-left: 1px solid $gray1;
      }
      &--meteo {
        grid-area: meteo;
        &.last {
          border-bottom-left-radius: 5px;
        }
      }
      &--retenue {
        grid-area: retenue;
      }
      &--production {
        grid-area: production;
      }
      &--prevision,
      &--production {
        border-left: 1px dashed $gray2;
        border-right: 1px solid $gray1;
        &.last {
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}
