.container {
  &__sidebar {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: max-content auto;
    grid-template-rows: auto;
    grid-template-areas: 'sidebar main';
    &--ltr {
      grid-template-columns: auto max-content;
      grid-template-areas: 'main sidebar';
    }
    .sidebar {
      grid-area: sidebar;
      user-select: none;
      &--hidden {
        display: none;
      }
      &__item {
        display: flex;
        align-items: center;
      }
    }
    .main {
      grid-area: main;
    }
  }
}
