@import '../../../theme.scss';

.form {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas:
    'username'
    'password'
    'submit';
}
.username {
  grid-area: username;
}
.password {
  grid-area: password;
}
.submit {
  grid-area: submit;
  align-self: center;
  justify-self: right;
}
.label {
  width: 100%;
}
.button {
  display: inline-block;
  line-height: 1;
  border-radius: $button-borderRadius;
  padding: 8px;
  text-transform: none;
  font-size: $fontSizeMedium;
  font-weight: 600;
  cursor: pointer;
  color: $button-primary-color;
  background: $button-primary-background;
  border: 1px solid $button-primary-border;

  &:focus {
    outline: none;
  }
  &:hover {
    color: $button-primary-color;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $button-primary-color;
    background: $button-primary-backgroundActive;
    border: 1px solid $button-primary-borderActive;
  }
  &.busy {
    opacity: 0.65;
  }
  &.disabled {
    opacity: 0.65;
    color: #ced3d6;
    border-color: #e3e5e6;
    box-shadow: none;
    cursor: default;
    pointer-events: none;
  }
}
