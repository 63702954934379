@import '../theme.scss';

.select {
  &__control {
    &--is-focused {
      &:not(:hover),
      &:not(:focus) {
        border: none !important;
        box-shadow: none !important;
      }
    }
    height: inherit !important;
    border: none !important;
    border-radius: unset !important;
  }
  &__single {
    &-value {
      color: hsl(0, 0%, 50%) !important;
    }
  }
  &__menu {
    z-index: $zindex-dropdown !important;
    border-radius: unset !important;
    margin: unset !important;
    &-list {
      padding: unset !important;
    }
  }
  &__indicator {
    cursor: pointer;
    &-separator {
      visibility: hidden !important;
    }
  }
}
