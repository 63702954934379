$font-family-base: 'Rubik', 'Avenir Next', 'Helvetica Neue', sans-serif;
$font-family-mono: 'Monaco', 'Consolas', 'Courier New', monospace;

$offWhite: #faf9fb;
$offWhite2: #e7e1ec;
$whiteDark: #fbfbfc;
$white: #ffffff;
$white-darker: #f7f9f9;
$white-darkest: darken($white-darker, 6);

$gray: lighten(desaturate(#16111b, 8%), 30%);
$gray-light: desaturate(lighten(desaturate(#16111b, 8%), 30%), 5%);
$gray-lighter: lighten(desaturate(#16111b, 8%), 60%);
$gray-lightest: lighten(desaturate(#16111b, 8%), 80%);
$gray-dark: lighten(desaturate(#16111b, 8%), 20%);
$gray1: #bdb4c7;
$gray2: #9585a3;
$gray3: #645574;
$gray4: #4a3e56;
$gray5: #302839;
$gray6: #afa3bb;

$trim: $gray-lightest;

$blue: #3b6ecc;
$blueLight: #628bd6;
$blueDark: #2f58a3;

$green: #57be8c;
$greenLight: #71d8a6;
$greenDark: #3ea573;
$greenTransparent: rgba(87, 190, 140, 0.5);

$yellow: #ecc844;
$yellowLightest: #fffdf7;
$yellowLight: #fff15e;
$yellowDark: #e6bc23;

$yellowOrange: #f9a66d;
$yellowOrangeLight: #ffc087;
$yellowOrangeDark: #e08d54;

$orange: #ec5e44;
$orangeLight: #ff785e;
$orangeDark: #d3452b;

$red: #e03e2f;
$redLight: #fa5849;
$redDark: #c72516;

$pink: #f868bc;
$pinkLight: #ff82d6;
$pinkDark: #df4fa3;

$purple: #6c5fc7;
$purple2: #6f617c;
$purple3: #6e00ff;
$purpleLightest: #9f92fa;
$purpleLight: #8679e1;
$purpleDark: #5346ae;
$purpleDarkest: #392c94;
$purpleSuperDark: #2f2936;

$white: #ffffff;
$white-dark: #fbfbfc;
$white-darker: #f7f8f9;
$white-darkest: darken($white-darker, 6);

$borderLighter: #f9f6fd;
$borderLight: #e2dbe8;
$borderDark: #d1cad8;
$borderRadius: 4px;
$borderRadiusBottom: 0 0 4px 4px;

$dropShadowLight: 0 2px 0 rgba(37, 11, 54, 0.04);
$dropShadowHeavy: 0 1px 4px 1px rgba(47, 40, 55, 0.08), 0 4px 16px 0 rgba(47, 40, 55, 0.12);

$background: #fff;
$foreground: #493e54;

$headerFontSize: 22px;

$zindex-orgAndUserMenu: 1004;
$zindex-globalSelectionHeader: 1002;
$zindex-sidebar: 1010;
$zindex-dropdown: 1001;
$zindex-dropdownAutoComplete-menu: 1007;
$zindex-dropdownAutoComplete-actor: 1008;
$zindex-header: 1000;
$zindex-sticky-bar: 1000;
$zindex-modal: 10000;
$zindex-toast: 10001;

$sidebar-width: 220px;
$sidebar-collapsed-width: 70px;
$sidebar-badgeSize: 22px;
$sidebar-smallBadgeSize: 11px;

$space-0: 0;
$space-0-25: 2px;
$space-0-5: 4px;
$space-1: 8px;
$space-1-25: 10px;
$space-1-5: 12px;
$space-2: 16px;
$space-3: 20px;
$space-4: 30px;

$grid: 8;
$fontSizeSmall: 12px;
$fontSizeMedium: 14px;
$fontSizeLarge: 16px;
$fontSizeExtraLarge: 18px;
$headerFontSize: 22px;

$disabled: $gray1;

$button-borderRadius: 3px;
$button-default-color: #2f2936;
$button-default-colorActive: #161319;
$button-default-background: $white;
$button-default-backgroundActive: $white;
$button-default-border: #d8d2de;
$button-default-borderActive: #c9c0d1;
$button-primary-color: $white;
$button-primary-background: $purple3;
$button-primary-backgroundActive: #4e3fb4;
$button-primary-border: #3d328e;
$button-primary-borderActive: #352b7b;
$button-success-color: $white;
$button-success-background: #3fa372;
$button-success-backgroundActive: $green;
$button-success-border: #7ccca5;
$button-success-borderActive: #7ccca5;
$button-danger-color: $white;
$button-danger-background: $red;
$button-danger-backgroundActive: #bf2a1d;
$button-danger-border: #bf2a1d;
$button-danger-borderActive: #7d1c13;
$button-link-color: $blue;
$button-link-background: transparent;
// $button-link-border: #3d328e;
$button-link-backgroundActive: transparent;
// $button-link-borderActive: #352b7b;
$button-disabled-color: $disabled;
$button-disabled-border: #e3e5e6;
$button-disabled-borderActive: #e3e5e6;
$button-disabled-background: $white;
$button-disabled-backgroundActive: $white;

$headerSelectorRowHeight: 44;

$alert-info-backgroundLight: #f5fafe;
$alert-info-border: #b5d6ed;
