@import '../theme.scss';

body {
  margin: 0;
  font-family: $font-family-base;
  font-size: 16px;
  line-height: 24px;
  color: $gray5;
  background: $white-dark;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  div[id='root'] {
    width: 100%;
    height: 100%;
  }
}
